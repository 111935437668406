@import '../variables.scss';

.drag-card{
    // width: 45rem;
    // height: 70rem;
    &__wrapper{
        border: 1px solid $accent;
        margin-right: 5rem;
        border-radius: 30rem;
        min-height: 70rem;
        padding: 4rem;
        user-select: none;
    }

    &__number{
        text-align: center;
        h1{
            font-size: 20rem;
            font-family: MadeMirage;
            color: $accent;
            font-weight: 300;
        }
    }

    &__title{
       
        h2{
            font-size: 4.4rem;
            font-family: MadeMirage;
            color: $accent;
            margin-bottom: 1.8rem;
        }
    }

    &__para{
       
        p{
            font-size: $p-size2;
            line-height: 1.6;
        }
    }
}

@media screen and (max-width: 768px){

   
    .drag-card__title h2{
        font-size: 10rem;
    }

    .drag-card__para p{
        font-size: 5.6rem;
    }

    .drag-card__number h1{
        font-size: 25rem;
    }

    .drag-card__wrapper{
        margin: auto;
        width: 90%;
        min-height: 130rem;
        padding: 6rem 10rem;
        border-radius: 50rem;
        height: 155rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .drag-card__title h2{
        font-size: 7.6rem;
    }

    .drag-card__para p{
        font-size: 4rem;
    }

    .drag-card__number h1{
        font-size: 35rem;
    }

    .drag-card__wrapper{
        margin: auto;
        width: 90%;
        min-height: 120rem;
        padding: 6rem 10rem;
        border-radius: 50rem;
        height: 120rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}
