@import '../variables.scss';

.lc{
    margin: 13rem 0;

    &__wrapper{
        display: flex;
    }

    &__title{
        width: 50%;
        h2{
            font-size: $h1-title;
            font-family: MadeMirage;
            position: relative;
            // &::after {
            //     content: '';
            //     position: absolute;
            //     bottom: -5%;
            //     left: 0;
            //     width: 50%;
            //     height: 1px;
            //     background-color: black;
            // }
        }
    }

    &__content{
        width: 35%;
        border: 1px solid $accent;
        padding: 5rem;
        border-radius: 20px;
        background: rgba(80, 22, 46, 0.09);
        p{
            font-size: $p-size3;
            &:first-child {
                margin-bottom: 3rem;
            }
            &:nth-child(2){
                margin-bottom: 6rem;

            }
            color: $accent;
        }
    }
}

@media screen and (max-width: 768px){
    .lc{
        margin: 25rem 0;

        .cta__button {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .lc__title h2{
        font-size: 18rem;
    }

    .lc__title{
        width: 100%;
        text-align: center;
    }

    .lc__wrapper{
        flex-direction: column;
    }

    .lc__content{
        margin-left: auto;
        margin-right: auto;
        margin-top: 10rem;
        width: 100rem;
        height: 100rem;
        padding: 8rem;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
        text-align: center;
    }

    .lc__content p{
        font-size: 5.6rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .lc{
        margin: 16rem 0;

        .cta__button {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .lc__title h2{
        font-size: 14rem;
    }

    .lc__title{
        width: 100%;
        text-align: center;
    }

    .lc__wrapper{
        flex-direction: column;
    }

    .lc__content{
        margin-left: auto;
        margin-right: auto;
        margin-top: 10rem;
        width: 100rem;
        height: 100rem;
        padding: 8rem;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
        text-align: center;
    }

    .lc__content p{
        font-size: 5.6rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}   

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}