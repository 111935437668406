@import '../../Components/variables.scss';


.wtc{
    max-width: 45rem;
    width: 100%;
    margin: auto;
    cursor: pointer;

    &:hover{
        .wtc__image img{
            transform: scale(1.1);
            transition: all 0.2s ease;
        }

        .wtc__arrow {
            padding-right: 0px;
            transition: all 0.2s ease;
        }
    }
    &__wrapper{
        width: 100%!important;
        padding-bottom: 30px;
        position: relative;
    }

    &__border{
        width: 100%;
        background-color: white;
        height: 1px;
    }
    &__image{
        width: 100%;
        position: relative;
        z-index: 2;

        border-radius: 300px 300px 0 0;
        height: 45rem;
        overflow: hidden;
        // &::before{
        //     content: '';
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     top: -3px;
        //     left: -20px;
        //     border: 1px solid rgba(255,255,255,0.3);
        //     z-index: 1;
        //     border-radius: 300px 300px 0 0;
        // }
        img{
            width: 100%;
            position: relative;
            z-index: 2;
            object-fit: cover;
            transition: all 0.2s ease;

        }
    }

    &__title{
        color: $color-white;
        margin-top: 27px;
        h3{
            font-size: $h3-title;
            font-family: MadeMirage;
            margin-bottom: 0px;
        }
    }

    &__caption{
        margin-top: 15px;
        margin-bottom: 20px;
        max-width: 80%;
        p{
            font-size: $p-size2;
            color: rgba(255,255,255,0.65);
            margin-bottom: 0px;
        }
    }

    // &__arch{
    //     width: 100%;
    //     height: 45rem;
    //     position: absolute;
    //     border: 1px solid rgba(255,255,255,0.65);
    //     border-radius: 300px 300px 0px 0px;
    //     left: -23px;
    //     top: -3px;
    // }

    &__arrow{
        text-align: right;
        margin-bottom: 20px;
        padding-right: 20px;
        transition: all 0.2s ease;

        img{
            width: 4.5rem;
        }
    }
}