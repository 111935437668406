@import '../variables.scss';

.wcu{
    padding: 13rem 0 0 0;
    margin-bottom: 13rem;
    overflow: hidden;
    // background-image: url('../../Assets/Images/hero-bg-3.png');
    // background-position: center center;
    // background-size: cover;
    // background-repeat: no-repeat;
    position: relative;
    &__wrapper{
        position: relative;
    }

    &__row{
        width: 100%;
        display: flex;
        flex-direction: row;

        .wcu__title{
            font-size: 5rem;
            font-family: MadeMirage;
            z-index: -2;
        }
    }

    &__col-50{
        width: 50%;
        overflow: hidden;
    }

    .ft-card__wrapper {
        margin-bottom: 10rem;
    }

    // &__title{
    //     position: fixed;
    // }
}

@media screen and (max-width: 768px){
    .wcu{
        padding: 25rem 0;
        margin-bottom: 0;
    }

    .wcu__row{
        flex-direction: column;
    }

    .wcu__col-50{
        width: 100%;
    }

    .wcu__row .wcu__title{
        font-size: 18rem;
        margin-bottom: 16rem;
    }

    .wcu__row .wcu__title{
        text-align: center;
    }

    .wcu .ft-card__wrapper{
        margin-bottom: 16rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .wcu{
        padding: 15rem 0;
        margin-bottom: 0;
    }

    .wcu__row{
        flex-direction: column;
    }

    .wcu__col-50{
        width: 100%;
    }

    .wcu__row .wcu__title{
        font-size: 14rem;
        margin-bottom: 16rem;
    }

    .wcu__row .wcu__title{
        text-align: center;
    }

    .wcu .ft-card__wrapper{
        margin-bottom: 16rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}
