@import '../variables.scss';

.accordion__main{
    margin-bottom: 13rem!important;
}

@media screen and (max-width: 768px){
    .accordion__main{
        margin-bottom: 25rem;
    } 
}

@media screen and (min-width: 769px) and (max-width: 992px){
    
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}
