@import './variables.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.left__arrow{
    position: absolute;
    width: 65px;
    height: 65px;
    transform: translate(-50%, -50%);
    left: 10%;
    top: 50%;
    padding: 20px;
    z-index: 5555;
    img{
        width: 100%;
    }
}

.right__arrow {
    position: absolute;
    width: 65px;
    height: 65px;
    transform: translate(-50%, -50%);
    right: 10%;
    top: 50%;
    padding: 20px;
    z-index: 5555;
    img{
        width: 100%;
    }
    
}


@media screen and (max-width: 768px){

    .inner__image {
        width: 100%;
    }
    .right__arrow {
        position: absolute;
        width: 65px;
        height: 65px;
        transform: translate(-50%, -50%);
        left: 60%;
        top: 75%;
        padding: 20px;
        img{
            width: 100%;
        }
        
    }

    .left__arrow{
        position: absolute;
        width: 65px;
        height: 65px;
        transform: translate(-50%, -50%);
        left: 40%;
        top: 75%;
        padding: 20px;
        img{
            width: 100%;
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .inner__image {
        width: 100%;
    }

    .right__arrow {
        position: absolute;
        width: 65px;
        height: 65px;
        transform: translate(-50%, -50%);
        left: 55%;
        top: 90%;
        padding: 20px;
        
    }

    .left__arrow{
        position: absolute;
        width: 65px;
        height: 65px;
        transform: translate(-50%, -50%);
        left: 45%;
        top: 90%;
        padding: 20px;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    // .inner__image {
    //     width: 100%;
    // }
    .right__arrow {
        position: absolute;
        width: 65px;
        height: 65px;
        transform: translate(-50%, -50%);
        left: 95%;
        top: 50%;
        padding: 20px;
    }

    .left__arrow{
        position: absolute;
        width: 65px;
        height: 65px;
        transform: translate(-50%, -50%);
        left: 5%;
        top: 50%;
        padding: 20px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}
