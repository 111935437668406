@import '../../Components/variables.scss';

.header{
    width: 100%;
    position: relative;
    z-index: 10000;
    background-color: $body-bg;
    &__wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        position: relative;
    }

    &__logo{
        max-width: 13rem;
        img{
            width: 100%;
        }
    }

    &__menu{
        &__list{
            ul{
                display: flex;
                margin-bottom: 0px;
                padding-left: 0px;
            }
            &__item{
                list-style: none;
                margin: 0 25px;
                color: $header-text;
                font-size: $p-size1;

                p{
                    margin-bottom: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .desktop{
        display: none;
    }

    .header__wrapper{
        height: 60px;
    }

    .header__logo {
        max-width: 40rem;
    }

    .header{
        width: 100%;
        position: fixed;
        z-index: 10000;
        padding: 0 5rem;
        top: 0;
        left: 0;
        .cta{
            display: none;
        }
        &__wrapper{
            position: relative;
        }
    }

    .mobile__menu{
        // position: relative;
        background-color: $body-bg;
    }

    .mobile__menu__ham{
        text-align: center;
        position: absolute;
        z-index: 100000;
        transform: translateY(-50%);
        cursor: pointer;
        // transform: translate(-50%, -50%);
        // left: 50%;
        // top: 50%;
        width: 30px;
        height: 15px;
        right: 0;
        div{
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            width: 30px;
            margin-bottom: 3px;
            height: 2px;
            background-color: $accent;
            transform-origin: center center;
            &:nth-child(1) {
                top: 0
            }

            &:nth-child(2) {
                top: 5px;
            }

            &:nth-child(3) {
                top: 10px;
            }
        }
    }

    .mobile__menu__list{
        position: fixed;
        width: 100%;
        height: calc(100vh - 60px);
        left: -100%;
        top: 60px;
        background-color: $body-bg;
        padding: 25rem 15rem 15rem 15rem;
        margin-bottom: 10rem;

        ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            li{
                margin: 0;
                border-bottom: 1px solid $accent;
                // margin-bottom: 40rem;

                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        max-width: 70px;
                        max-height: 16px;
                    }
                }
                
                p{
                    font-size: 13.5rem;
                    font-family: MadeMirage;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .desktop{
        display: none;
    }

    .header{
        width: 100%;
        position: fixed;
        z-index: 10000;
        top: 0;
        left: 0;
    }

    .header__wrapper{
        height: 60px;
    }
    .mobile__menu{
        // position: relative;
        background-color: $body-bg;
        
    }

    .mobile__menu__ham{
        text-align: center;
        position: absolute;
        z-index: 100000;
        cursor: pointer;
        width: 30px;
        height: 15px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        div{
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            width: 30px;
            margin-bottom: 3px;
            height: 2px;
            background-color: $accent;
            transform-origin: center center;
            &:nth-child(1) {
                top: 0
            }

            &:nth-child(2) {
                top: 5px;
            }

            &:nth-child(3) {
                top: 10px;
            }
        }
    }

    .mobile__menu__list{
        position: fixed;
        width: 100%;
        height: 100vh;
        left: -100%;
        // top: 0;
        top: 60px;
        background-color: $body-bg;
        padding: 25rem 15rem 0rem;
        margin-bottom: 10rem;

        ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            li{
                margin: 0;
                border-bottom: 1px solid $accent;
                margin-bottom: 20rem;

                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        max-width: 70px;
                        max-height: 20px;
                    }
                }
                
                p{
                    font-size: 10rem;
                    font-family: MadeMirage;
                }
            }
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .mobile__menu{
        display: none;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    .mobile__menu{
        display: none;
    }
}

@media screen and (min-width: 1600px) and (max-width: 7999px){
    .mobile__menu{
        display: none;
    }
}