@import '../variables.scss';

.feat-section{
    width: 100%;
    height: 100vh;
    position: relative;
    &__wrapper{
        position: relative;
        width: 100%;
        height: 100%;
        &__content{
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: centerx;
            p{
                margin-bottom: 0px;
                color: $color-black;
                font-size: $p-size2;
                color: $accent;
            }

            h2{
                margin-bottom: 6rem;
                color: $color-black;
                font-size: $h2-title;
                font-family: MadeMirage;
                color: $accent;
            }

            .cta{
                display: flex;
                justify-content: center;
            }
        }
    }

    .image1,.image2,.image3, .image4{
        position: absolute;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .image1{
        width: 40rem;
        height: 22rem;
        top: 10%;
        right: 10%;
        z-index: 1;
    }

    .image2{
        width: 40rem;
        height: 60%;
        right: 5%;
        top: 25%;
        z-index: 0;
    }

    .image3{
        width: 35rem;
        height: 60%;
        left: 9%;
        top: 10%;
    }

    .image4{
        width: 40rem;
        height: 25rem;
        left: 15%;
        top: 54%;
    }
}

@media screen and (max-width: 768px){
    .feat-section{
        height: 50vh;
    }

    .image1, .image4{
        display: none;
    }

    .feat-section__wrapper__content h2{
        font-size: 10rem;
    }

    .feat-section__wrapper__content p{
        font-size: 5.6rem;
        margin-bottom: 3rem;
    }

    .feat-section .image2{
        width: 45rem;
    }

    .feat-section .image3{
        width: 45rem;
        left: 5%;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .feat-section{
        height: 70vh;
    }

    .image1, .image4{
        display: none;
    }

    .feat-section__wrapper__content h2{
        font-size: 10rem;
    }

    .feat-section__wrapper__content p{
        font-size: 4rem;
        margin-bottom: 3rem;
    }

    .feat-section .image2{
        width: 45rem;
    }

    .feat-section .image3{
        width: 45rem;
        left: 5%;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}
