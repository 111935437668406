@import '../variables.scss';

.team{
    margin-top: 13rem;
    padding-top: 13rem;
    padding-bottom: 13rem;
    background: $accent;
    &__wrapper{
        display: flex;
        flex-direction: column;
    }

    &__head{
        h1{
            font-family: MadeMirage;
            font-size: $h1-title;
            text-align: center;
            margin-bottom: 0;
            color: white;
        }
    }

    &__caption{
        p{
            text-align: center;
            font-size: $p-size1;
            color: white;
        }
    }

    &__cards{
        display: flex;
        justify-content: space-between;
        margin-top: 6rem;
        &__item{
            background-color: white;
            width: 45rem;
            height: 45rem;
            padding: 4rem 4rem 3rem 4rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 20px;
            &__head{
                h2{
                    color: $accent;
                    font-size: $h3-title;
                    font-family: MadeMirage;
                }
            }

            &__content{
                p{
                    font-size: $p-size1;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .team__head h1 {
        font-size: 18rem;
    }

    .team{
        padding: 25rem 0;
    }

    .team__caption p{
        font-size: 5.5rem;
        margin-bottom: 6rem;
    }

    .team__cards{
        flex-direction: column;
    }

    .team__cards__item{
        width: 120rem;
        height: 120rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16rem;
        padding: 8rem;

        
    }

    .team__cards__item__head h2{
        font-size: 10rem;
    }

    .team__cards__item__content p {
        font-size: 5.5rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .team__head h1 {
        font-size: 14rem;
    }

    .team{
        padding: 16rem 0;
    }

    .team__caption p{
        font-size: 5.5rem;
        margin-bottom: 6rem;
    }

    .team__cards{
        flex-direction: column;
    }

    .team__cards__item{
        width: 100rem;
        height: 100rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16rem;
        padding: 8rem;

        
    }

    .team__cards__item__head h2{
        font-size: 7.6rem;
    }
    
    .team__cards__item__content p {
        font-size: 4rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}