@import './variables.scss';

.ib{
    &__wrapper{
        width: 100%;
    }

    &__title{
        margin-top: 13rem;
        margin-bottom: 3rem;
        h1{
            text-align: center;
            font-size: $h2-title;
            font-family: MadeMirage;
        }
        
    }

    &__date{
        text-align: center;
        p{
            color: $accent;
            font-size: $p-size2;
        }
    }

    &__content{
        margin-top: 6rem;
        overflow: hidden;
        p{
            font-size: $p-size2;
            margin: 15px 0;
        }
    }
}

.next-prev-blogs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    a{

        h2 {
            font-size: 2.2rem;

        }
    }

    .next-link, .prev-link {
        display: flex;
        align-items: center;

        img {
            width: 30px;
            margin: 0 10px;
        }

        h2{
            margin-bottom: 0px;
        }
    }
}

@media screen and (max-width: 768px){
    .ib{
        width: 100%;
        overflow: hidden;
        img{
            // width: 100%;
            width: 100%;
            display: inline;
            height: 100%;
            object-fit: cover
        }
    }
    .ib__wrapper{
        margin-top: 60px;
    }

    .ib__title {
        padding-top: 13rem;
    }

    .ib__title h1{
        font-size: 18rem;
    }

    .ib__date p {
        font-size: 5.6rem;
    }

    .ib__content{
        figure{
            width: 100%!important;
        }
        img{
            // width: 100%;
            width: 100%!important;
            display: inline!important;
            height: 100%!important;
            object-fit: cover!important;
        }
        p {
            font-size: 7.9rem;
            line-height: 1.8;
            margin: 20px 0;
        }
    }

    // .next-prev-blogs .next-link{
    //     flex-direction: column;
    // }

    .next-prev-blogs .next-link img, .next-prev-blogs .prev-link img{
        width: 10px;
    }

    .next-prev-blogs {
        flex-direction: column;
        align-items:center ;
        text-align: center;

        .next-link h2 {
            font-size: 6rem;
            text-align: center;
        }

        .next-link, .prev-link {
            margin-bottom: 5px;
        }

        .prev-link h2 {
            font-size: 6rem;
            text-align: center;
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .ib{
        width: 100%;
        overflow: hidden;
        img{
            width: 100%;
            display: inline;
            height: 100%;
            object-fit: cover;
        };
    }
    .ib__wrapper{
        margin-top: 60px;
    }

    .ib__title {
        padding-top: 13rem;
    }

    .ib__title h1{
        font-size: 14rem;
    }

    .ib__date p {
        font-size: 4rem;
    }

    .ib__content{
        p {
            font-size: 4rem;
            line-height: 1.8;
        }
    }

    .next-prev-blogs .next-link img, .next-prev-blogs .prev-link img{
        width: 15px;
    }

    .next-prev-blogs {
        flex-direction: column;
        align-items:center ;
        text-align: center;

        .next-link h2 {
            font-size: 3rem;
            text-align: center;
        }

        .next-link, .prev-link {
            margin-bottom: 5px;
        }

        .prev-link h2 {
            font-size: 3rem;
            text-align: center;
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}
.loading-anim {
    width: 100%;
    height: 100vh;
    text-align: center;
    font-size: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}