@import '../variables.scss';

.fc{
    width: 45rem;
    height: 45rem;
    position: relative;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $accent;
    background: rgba(80,22,46, 0.09);
    &__wrapper{
        padding: 4rem 4rem 3rem 4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }

    &__title{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        h2{
            font-size: $h3-title;
            font-family: MadeMirage;
            color: $accent;
        }
        img{
            width: 8rem;
            opacity: 0;
        }
    }

    &__text{
        p{
            font-size: $p-size1;
            color: $accent;
        }
    }
}

@media screen and (max-width: 768px){
    .fc{
        width: 120rem;
        height: 120rem;
    }

    .fc__title h2{
        font-size: 10rem;
    }

    .fc__wrapper{
        padding: 8rem;
    }

    .fc__text p{
        font-size: 5.6rem;
    }

    .fc__title img{
        width: 15rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .fc{
        width: 100rem;
        height: 100rem;
    }

    .fc__title h2{
        font-size: 7.6rem;
    }

    .fc__wrapper{
        padding: 8rem;
    }

    .fc__text p{
        font-size: 4rem;
    }

    .fc__title img{
        width: 15rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}


