$color-black: #000;
$color-white: #fff;
$body-bg: #fffdf5;
$section-bg: #50162E;
$header-text: #50162E;
$accent: #50162E;

$p-size1: 1.8rem;
$p-size2: 2.3rem;
$p-size3: 3rem;

$hmain-title: 11.5rem;
$h1-title: 9rem;
$h2-title: 7.5rem;
$h3-title: 3.6rem;

@media screen and (max-width: 768px){
    
}

@media screen and (min-width: 769px) and (max-width: 992px){
    
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}




