@import '../variables.scss';

.contact-form{
    width: 80%;
    text-align: center;
    margin: auto;
    padding: 0 0 13rem 0;

    .contact-info-wrapper {
        width: 80%;
        margin: auto;
        .contact-info-item {
            margin-bottom: 15px;
            display: flex;
            justify-content: left;
            text-align: left;
            align-items: center;

            h2{
                color: #50162E;
                font-size: 3rem;
            }
            img{
                width: 32px;
                height: 32px;
                margin-right: 10px;
            }

            p{
                font-size: 2rem;
                margin-bottom: 0px;
            }
        }

        
    }
    form{
        width: 100%;

        .form-row{
            width: 100%;
            margin-bottom: 3rem;
            h4{
                font-size: $p-size2;
            }

            input{
                // height: 4.5rem;
                text-align: center;
            }

            input, textarea{
                width: 100%;
                font-size: $p-size2;

            }

            textarea:focus, input:focus{
                outline: 1px solid $accent;
            }
        }

        .submit-button{
            width: 100%;
            display: flex;
            padding: 3rem 0 0 0;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 768px){
    .contact-form{
        width: 80%;
        text-align: center;
        margin: auto;
        padding: 0 0 13rem 0;

        .contact-info-wrapper {
            width: 90%;
            margin: auto;
            .contact-info-item {
                margin-bottom: 10px;
                display: flex;
                justify-content: left;
                text-align: left;
                align-items: center;
    
                h2{
                    color: #50162E;
                    font-size: 8rem;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 30px;
                }
                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
    
                p{
                    font-size: 6rem;
                    margin-bottom: 0px;
                }
            }
    
            
        }
        form{
            width: 100%;
    
            .form-row{
                width: 100%;
                margin-bottom: 3rem;
                h4{
                    font-size: 8rem;
                }
    
                input{
                    // height: 4.5rem;
                    text-align: center;
                }
    
                input, textarea{
                    width: 100%;
                    font-size: 8rem;
    
                }
    
                textarea:focus, input:focus{
                    outline: 1px solid $accent;
                }
            }
    
            .submit-button{
                width: 100%;
                display: flex;
                padding: 3rem 0 0 0;
                justify-content: center;
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .contact-form{
        width: 80%;
        text-align: center;
        margin: auto;
        padding: 0 0 13rem 0;

        .contact-info-wrapper {
            width: 90%;
            margin: auto;
            .contact-info-item {
                margin-bottom: 10px;
                display: flex;
                justify-content: left;
                text-align: left;
                align-items: center;
    
                h2{
                    color: #50162E;
                    font-size: 4rem;
                    text-align: center;
                    // margin-left: auto;
                    // margin-right: auto;
                    margin-top: 30px;
                }
                img{
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;
                }
    
                p{
                    font-size: 3rem;
                    margin-bottom: 0px;
                }
            }
    
            
        }

        form{
            width: 100%;
    
            .form-row{
                width: 100%;
                margin-bottom: 3rem;
                h4{
                    font-size: 6rem;
                }
    
                input{
                    // height: 4.5rem;
                    text-align: center;
                }
    
                input, textarea{
                    width: 100%;
                    font-size: 6rem;
    
                }
    
                textarea:focus, input:focus{
                    outline: 1px solid $accent;
                }
            }
    
            .submit-button{
                width: 100%;
                display: flex;
                padding: 3rem 0 0 0;
                justify-content: center;
            }
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}
