@import '../variables.scss';

.circle{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 13rem 0 0 0;
    .circle-img__wrapper{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
        transform: translate(-50%, -50%);
        img{
            width: 100%;
            animation: animate-circle 20s linear infinite;
        }

        @keyframes animate-circle {
            0%{
                transform: rotate(0deg);
            }

            100%{
                transform: rotate(360deg);
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0%;
            width: 20%;
            top: 50%;
            height: 1px;
            transform: translate(-50%, -50%);
            background: $accent;
        }

        &::after {
            content: '';
            position: absolute;
            transform: translate(-50%, -50%);
            left: 100%;
            top: 50%;
            width: 20%;
            height: 1px;
            background: $accent;
        }
    }

    .rings__wrapper{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img{
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px){
    .circle{
        height: 50vh;
        margin: 0;
    }

    .circle .circle-img__wrapper{
        width: 100%;
    }

    .circle .rings__wrapper{
        width: 30%;
    }

    .circle .circle-img__wrapper::before { 
        display: none;
    }

    .circle .circle-img__wrapper::after { 
        display: none;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px){
    .circle{
        height: 60vh;
        margin: 0;
    }

    .circle .circle-img__wrapper{
        width: 90%;
    }

    .circle .rings__wrapper{
        width: 20%;
    }

    .circle .circle-img__wrapper::before { 
        display: none;
    }

    .circle .circle-img__wrapper::after { 
        display: none;
    }
}

@media screen and (min-width: 993px) and (max-width: 1200px){
    .circle{
        // height: 60vh;
        margin: 0;
    }

    .circle .circle-img__wrapper{
        width: 70%;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1599px){
    
}
