@import './Components/variables.scss';
@font-face {
    font-family: MadeMirage;
    src: url("./Assets/Fonts/MADE\ Mirage\ Regular\ PERSONAL\ USE.otf") format("opentype");
}

*{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
    color: $accent;

    &:hover{
        color: $accent;
        // opacity: 0.5;;
    }
}

a:active, a:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
    box-shadow: none;
}

body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: $body-bg;
}

html{
    font-size: calc(100vw/1920 * 10);
}

#scroller{
    width: 100%;
    height: 100vh;
}